<template>
  <section class="views">
    <headers select="3"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.png')" alt="" />
      </div>

       <!-- 比赛类型Tab -->
      <div class="competition-types">
        <div class="type-tabs">
          <div
            v-for="item in competitionType"
            :key="item.value"
            @click="changeCompetition(item.value)"
            :class="{ active: item.value === currentCompetition }"
            class="tab-item"
          >
            {{ item.title }}
          </div>
        </div>
      </div>

      <div class="viewsBoxTab">
        <div class="viewsTab" ref="timeSlide">
          <ul>
            <li
              v-for="(item, index) in tabColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select == index ? 'select' : ''"
              :ref="`tab${index}Ref`"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="shortlisted-query" v-if="false">
        <div class="contentFrom_title">入圍名單查詢</div>
        <div class="plateUlsCentent6">
          <ul>
            <li>
              <div class="prompt" style="cursor: pointer">
                <div
                  :class="choose == 0 ? 'highlight' : ''"
                  @click="chooseBut(0)"
                >
                  參賽手機
                </div>
                <div
                  :class="choose == 1 ? 'highlight' : ''"
                  @click="chooseBut(1)"
                >
                  參賽電郵
                </div>
              </div>
              <div class="promptInput2" v-if="choose == 0">
                <div class="lis">
                  <div class="lisLife">
                    <!-- <img :src="require('@/assets/image/hk.png')" />
										<span>+852</span> -->
                    <CountryCodeSelector
                      :searchbar="false"
                      :countryCode.sync="prefix"
                      style="width: 90px; margin-right: 5px"
                    ></CountryCodeSelector>
                  </div>
                  <input
                    class="lisRight"
                    type="text"
                    placeholder="請輸入參賽電話號碼"
                    value=""
                    v-model.trim="from.phone"
                  />
                </div>
              </div>
              <div style="font-size: 0px" v-if="choose == 1">
                <input
                  class="promptInput"
                  placeholder="請輸入參賽郵箱"
                  v-model.trim="from.email"
                />
              </div>
            </li>
            <li>
              <div class="prompt">姓名</div>
              <div style="font-size: 0px">
                <input
                  class="promptInput"
                  placeholder="請輸入參賽者姓名"
                  v-model="from.school_name"
                />
              </div>
            </li>
          </ul>

          <div
            class="but"
            @click="finalistsBut ? finalists() : ''"
            style="cursor: pointer"
          >
            查詢
          </div>
        </div>
      </div>
      <div class="viewTabContent">
        <!-- <div class="ulsTab" ref="tabSlideTwo">
          <ul>
            <li
              v-for="(item, index) in tabColumns[select].dataTab"
              :key="index"
              :class="selectTwo == index ? 'select' : ''"
              @click="tabButTwo(index)"
              :ref="`tab${index}RefTwo`"
            >
              <div>{{ item.name }}</div>
              <div>（{{ item.fzhu }}）</div>
            </li>
          </ul>
        </div> -->

        <div class="box">
          <ul>
            <!-- <li class="weiyitop">
              <div>參賽序號</div>
              <div>姓名</div>
              <div>電話</div>
            </li>

            <li
              class="lis"
              v-for="(item, index) in queryUserList"
              :key="index"
              v-if="select != 2"
            >
              <div>{{ item.competitionId }}</div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.phone }}</div>
            </li> -->

            <div class="wu">
                <img :src="require('@/assets/image/hodgepodge/wu.png')" alt="" />
                <span>
                  當前階段成績尚未揭曉哦
                </span>
              </div>
          </ul>
          <!-- <ul v-if="select === 2">
            <li class="weiyitop">
              <div>獎項</div>
              <div>姓名</div>
              <div>電話</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>
                {{
                  item.ranking == 1
                    ? '金獎'
                    : item.ranking == 2
                    ? '銀獎'
                    : item.ranking == 3
                    ? '銅獎'
                    : item.ranking > 3
                    ? '優異獎'
                    : ''
                }}
              </div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.phone }}</div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <!-- 未入圍/成功入園 -->
    <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
      <div class="newShortBox">
        <div class="list" v-if="newShortActive == 1">
          <!-- <div class="lis lisCenten"> <van-image width="149px" :src="require('@/assets/image/fusai/yes.png')" /> </div> -->
          <!-- <div class="ulst">
						<ul>
							<li>姓名</li>
							<li>組別</li>
							<li>分數</li>
						</ul>
						<ul>
							<li>{{userObj.studentName == null? '': userObj.studentName}}</li>
							<li>{{userObj.competitionGroup == null? '': userObj.competitionGroup}}</li>
							<li>{{userObj.score == null? '': userObj.score}}</li>
						</ul>
					</div>  -->
          <div class="lis lisText">
            恭喜你，成功入圍決賽，<br />請詳閱電郵關於決賽暨頒獎典禮通知函，期待你的出席。
          </div>
          <!-- <div class="but" @click="handelGuide">點擊查看複賽指引</div> -->
        </div>

        <div class="list" v-if="newShortActive == 2">
          <!-- <div class="ulst">
						<ul>
							<li>姓名</li>
							<li>組別</li>
							<li>分數</li>
						</ul>
						<ul>
							<li>{{userObj.studentName == null? '': userObj.studentName}}</li>
							<li>{{userObj.competitionGroup == null? '': userObj.competitionGroup}}</li>
							<li>{{userObj.score == null? '': userObj.score}}</li>
						</ul>
					</div> -->
          <div class="lis lisText">
            很遺憾！你未獲入圍決賽，非常感謝你的積極參與，複賽評分紙將會在14個工作日內通過電郵發送，請注意查收電郵。
          </div>
        </div>
      </div>
    </van-popup>
  </section>
</template>

<script>
import headers from '@/components/header/h_header'
import CountryCodeSelector from '@/components/CountryCodeSelector'
export default {
  name: 'views',
  components: {
    headers,
    CountryCodeSelector
  },
  data() {
    return {
      // 比赛类型
      currentCompetition: '1',
      competitionType:[
        {
          title:'個人賽',
          value:'1'
        },
        {
          title:'團體赛',
          value:'2'
        },
      ],
      tabColumns1: [
        {
          id: '0',
          name: '初賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            }
          ]
        },
        {
          id: '1',
          name: '複賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            }
          ]
        },
        {
          id: '2',
          name: '決賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            }
          ]
        }
      ],
      tabColumns2: [
        {
          id: '0',
          name: '初賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            }
          ]
        },
        {
          id: '2',
          name: '決賽成績',
          dataTab: [
            {
              name: '幼稚園初級組',
              fzhu: 'K1-K2'
            },
            {
              name: '幼稚園高級組',
              fzhu: 'K3'
            },
            {
              name: '小學初級組',
              fzhu: 'P1-P2'
            },
            {
              name: '小學中級組',
              fzhu: 'P3-P4'
            },
            {
              name: '小學高級組',
              fzhu: 'P5-P6'
            },
            {
              name: '初中組',
              fzhu: 'S1-S3'
            }
          ]
        }
      ],
      select: 0,
      selectTwo: 0,
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null
      },
      prefix: 852,
      finalistsBut: true,
      newShortZZC: false, //入圍 彈窗
      newShortActive: 1,
      userObj: {
        competitionGroup: 1, //組別
        score: null, //分數
        studentName: null //名字
      },
      queryUserList: []
    }
  },
  computed: {
    tabColumns () {
      return this.currentCompetition === '1' ? this.tabColumns1 : this.tabColumns2
    }
  },
  
  watch: {
    select(val) {
      if (val) {
        console.log('發送改變', val)
      }
    }
  },
  created() {},
  mounted() {
    this.getScoreList(1)
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollLeftTo(2)
      }, 1000)
    })
  },
  methods: {
    changeCompetition(value) {
      this.currentCompetition = value
      this.select = 0;
      this.selectTwo = 0
    },
    tabButTwo(e) {
      this.selectTwo = e
      // if(this.select != 2){
      this.getScoreList(1)
      // }
      this.scrollLeftToTwo(e)
    },
    tabBut(e) {
      this.select = e
      // if(this.select != 2){
      this.getScoreList(1)
      // }
      // 触发滑动方法
      this.scrollLeftTo(e)
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`
      const nav = this.$refs.timeSlide
      console.log('额:', this.$refs.timeSlide.offsetWidth)
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 滑动
    scrollLeftToTwo(name) {
      const ref = `tab${name}RefTwo`
      const nav = this.$refs.tabSlideTwo
      // console.log("额:", this.$refs.tabSlideTwo.offsetWidth);
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 切換
    chooseBut(e) {
      this.choose = e
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // 入圍按鈕提交
    finalists() {
      let params

      if (this.choose == 0) {
        params = {
          studentName: this.from.school_name,
          prefix: this.prefix,
          phone: this.from.phone,
          email: null
        }
      } else if (this.choose == 1) {
        params = {
          studentName: this.from.school_name,
          prefix: null,
          phone: null,
          email: this.from.email
        }
      }

      if (params.studentName == '' || params.studentName == null) {
        this.$toast('請輸入您的姓名 !')
        return
      }
      if (this.choose == 0) {
        // 手機
        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }
      } else if (this.choose == 1) {
        // 郵箱
        if (!this.validator_email(params.email)) {
          this.$toast('電郵格式有誤 !')
          return
        }
      }
      params.scoreType = this.select + 1
      this.finalistsBut = false
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      // 正式環境請求
      // this.$axios
      // .post(this.$api.getPersonInfo,params)

      // 使用代理的方式請求
      // this.$axios({
      // 	url: '/api/statistics/competition/getPersonInfo',
      // 	method: 'post',
      // 	data: params
      // })
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.finalistsBut = true
          console.log(response)
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            //请求成功
            // this.$toast.success('獲取成功');
            this.userObj.competitionGroup = response.data.data.competitionGroup //組別
            this.userObj.score = response.data.data.score //分數
            this.userObj.studentName = response.data.data.studentName //名字

            this.$toast.clear()
            if (response.data.data.shortlisted) {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 1 //1是成功  2是失敗
            } else {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 2 //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.finalistsBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    getScoreList(pageNum) {
      return
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      let groupId = this.selectTwo + 1
      let scoreType = this.select + 1
      let params =
        '?groupId=' +
        groupId +
        '&scoreType=' +
        scoreType +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        100
      if (this.select == 1) {
        params = params + '&ranking=' + 15
      }
      this.$axios
        .get(this.$api.promotionStudentList + params)
        .then((response) => {
          this.$toast.clear()
          let arr = response.data.rows
          if (pageNum === 1) {
            this.queryUserList = arr
          } else {
            this.queryUserList = [...this.queryUserList, ...arr]
          }
          // 数据最多返回100条，超出请求2次
          if (response.data.total > 100 && pageNum === 1) {
            this.getScoreList(2)
          }
        })
        .catch((error) => {
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.competition-types {
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 30px;
  background: #d34a00;
  z-index: 99;
  .type-tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    .tab-item {
      min-width: 2rem;
      padding: 0.35rem 0.12rem;
      color: #fff;
      cursor: pointer;
      font-size: 0.32rem;

      &.active {
        font-weight: 600;
        color: #903300;
        background-image: url("~@/assets/image/hodgepodge/tabbuttom.png");
        background-size: 100% 100%;
        transform: scale(1.2);
      }
    }
  }
}
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
    display: none;
  }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow: scroll;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab {
      width: 100%;
    }

    .viewsTab {
      width: 100%;
      padding: 0.16rem 0;
      box-sizing: border-box;
      overflow-y: scroll;
      margin-top: -6%;

      ul {
        width: 100%;
        height: 1.28rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d34a00;

        li {
          width: 33%;
          height: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold,PingFang SC;
          line-height: .38rem;
          flex: 0 0 auto;
          cursor: pointer;

          div {
            width: 100%;
            font-size: .32rem;
            line-height: 1.2;
            font-family: PingFang SC-Regular,PingFang SC;
            font-weight: 400;
            color: #fff;
          }
        }

        .select {
          font-weight: 600;
          color: #903300;
          // padding: 0.16rem 0;
          background: url("~@/assets/image/hodgepodge/tabbuttom.png") no-repeat;
          background-size: 100% 100%;

          div {
            font-size: 0.4rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.47rem !important;
          }
        }
      }
    }

    .viewTabContent {
      width: 100%;

      .ulsTab::-webkit-scrollbar {
        display: none;
      }

      .ulsTab {
        width: 100%;
        padding: 0.32rem 0 0 0.32rem;
        box-sizing: border-box;
        overflow-y: scroll;

        ul {
          width: 100%;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;

          li {
            margin-right: 0.32rem;
            min-width: 2.4rem;
            max-width: 80%;
            height: 1.32rem;
            background: #ffffff;
            border: 0.04rem solid #ffffff;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 0.38rem;
            flex: 0 0 auto;
            text-align: center;
            border-radius: 0.32rem;

            div {
              width: 100%;
              text-align: center;
            }

            div:nth-child(1) {
              font-size: 0.32rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 0.3rem;
              margin-top: 0.04rem;
            }
          }
          .select {
            background: #fdd472 !important;

            div:nth-child(1) {
              font-size: 0.32rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.38rem;
            }

            div:nth-child(2) {
              font-size: 0.26rem !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 0.3rem !important;
            }
          }
        }
      }

      .box {
        width: 100%;
        padding: 0.32rem;
        padding-top: 0.64rem;
        box-sizing: border-box;

        ul::-webkit-scrollbar {
          display: none;
        }

        ul {
          width: 100%;
          height: 70vh;
          border-radius: 0.16rem;
          background: #ffffff;
          border: 0.06rem solid #ffffff;
          box-sizing: border-box;
          overflow-x: hidden;
          position: relative;

          .wei {
            width: 100%;
            height: 0.92rem;
          }

          .weiyitop {
            width: 100%;
            height: 0.92rem;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              width: 33%;
              text-align: center;
              font-size: 0.28rem;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 0.92rem;
            }
          }

          .lis {
            width: 100%;
            height: 0.92rem;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 0.01rem;

            div {
              width: 33%;
              font-size: 0.28rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 0.92rem;
              text-align: center;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
        .wu {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          align-content: center;

          img {
            width: 4.36rem;
            margin-bottom: 0.56rem;
          }

          span {
            width: 100%;
            font-size: 0.32rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 0.38rem;
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      max-width: 1024px;
      margin: auto;
      margin-top: 64px;
      padding: 0.32rem;
      box-sizing: border-box;

      ul {
        width: 100%;

        li {
          width: 640px;
          height: 124px;
          margin: auto;
          background: #fff8de;
          box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
          border: 4px solid #fff0e1;
          border-radius: 16px;
          margin-bottom: 32px;
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff782f;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.shortlisted-query {
  width: 90%;
  max-width: 600px;
  margin: 70px auto 0 auto;
  position: relative;
  .contentFrom_title {
    width: 80%;
    height: 50px;
    margin: auto;
    border-radius: 9px;
    background: url("~@/assets/image/icon/titlebg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    margin: auto;

    text-shadow: 1.5px 1.5px 0 #ff782f, -1.5px 1.5px 0 #ff782f,
      1.5px -1.5px 0 #ff782f, -1.5px -1.5px 0 #ff782f;
  }

  .plateUlsCentent6 {
    width: 100%;
    padding: 40px 12px 15px 12px;
    border-radius: 8px;
    box-sizing: border-box;
    background: #ffffff;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;

      li {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;

        .prompt {
          height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;

          div {
            margin-right: 11px;
            text-align: center;
            line-height: 30px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: " ";
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 12px !important;
            color: #de2810;
          }
        }

        .lis {
          width: 100%;
          height: 34px;
          line-height: 34px;
          background: #f5f5f5;
          display: flex;
          border-radius: 6px 6px 6px 6px;
          // overflow: hidden;

          .lisLife {
            display: flex;
            align-items: center;
            font-family: PingFang SC-Regular, PingFang SC;
            color: #4a4a4a;
            padding-left: 10px;

            img {
              width: 16px;
              height: 11px;
            }
          }

          .lisRight {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }
        .row-input {
          display: flex;
          align-items: center;
          height: 34px;
          font-size: 14px;
          line-height: 34px;
          background: #f5f5f5;
          box-sizing: border-box;
          padding-left: 13px;
          border-radius: 6px;
          overflow: hidden;

          input {
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #f5f5f5;
            border: none;
            box-sizing: border-box;
          }
        }

        input::-webkit-input-placeholder {
          color: #999999;
        }
        /* 使用webkit内核的浏览器 */
        input:-moz-placeholder {
          color: #999999;
        }
        /* Firefox版本4-18 */
        input::-moz-placeholder {
          color: #999999;
        }
        /* Firefox版本19+ */
        input:-ms-input-placeholder {
          color: #999999;
        } /* IE浏览器 */

      }
    }

    .uls:first-child {
      margin-right: 60px;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url("~@/assets/image/icon/but.png") no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding-bottom: 20px;
      margin: 15px auto 0 auto;
      transition: all 0.3s;
      &:active {
        transform: scale(1.02);
      }
    }
  }
}

.newShortBox {
  padding: 12px 0 20px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .lis {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 149px;
      }
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      font-size: 12px;

      span {
        color: #ff5a25;
      }
    }

    .ulst {
      width: 100%;
      padding: 0 12px;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px;

        li {
          width: 33.3%;
          text-align: center;
          font-size: 14px;
          padding: 5px;
        }
        li:nth-child(2) {
          width: 30% !important;
        }
      }

      ul:nth-child(1) {
        padding: 5px 0;
        background: #ff5a25;

        li {
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        margin-top: 10px;
        margin-bottom: 20px;

        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      padding: 0 12px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 1.5;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url("~@/assets/image/but.png") no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}
.view_guide {
  margin: 0 auto;
  padding: 0 12px;
  line-height: 32px;
  background: #ff7e18;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  border-radius: 4px;
  color: #ffffff;
}

::v-deep .CountryCodeSelectorContainer {
  width: 100px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
</style>
